import React, { useEffect } from 'react'
import { backdropAnimation, modalAnimation } from 'ui/animations/animations'

import media from 'styled-media-query'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'

export default function Discard({ ...props }) {
  const setDiscard = useStore((state) => state.setDiscard)
  const discardPosts = useStore((state) => state.discardPosts)
  const cancelDiscard = useStore((state) => state.cancelDiscard)
  const { getString } = useTranslations()

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setDiscard(false)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  return (
    <Style {...props}>
      <motion.div
        key="modal"
        className="modal"
        variants={modalAnimation}
        initial="hidden"
        animate="show"
        exit="hidden"
      >
        <div className="discard-info">
          <h1>{getString('discardTitle', 'Discard posts?')}</h1>
          <p>
            {getString('discardDescription', 'This will remove your posts')}
          </p>
        </div>

        <div className="vertical-button-group">
          <div className="negative" onClick={() => discardPosts()}>
            {getString('discard', 'Discard')}
          </div>
          <div onClick={() => cancelDiscard()}>
            {getString('cancel', 'Cancel')}
          </div>
        </div>
      </motion.div>
      <Backdrop
        onClick={() => cancelDiscard()}
        key="backdrop"
        variants={backdropAnimation}
        initial="hidden"
        animate="show"
        exit="hidden"
      />
    </Style>
  )
}

const Backdrop = styled(motion.div)`
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0px;
  left: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Style = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999999999999999999;

  .modal {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 0px 8px 40px -10px rgb(0 0 0 / 15%);
    border-radius: var(--border-radius);
    background: #fff;
    max-width: 500px;
    width: calc(100% - 40px);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    ${media.greaterThan('medium')`
      width: 100%;
    `}
  }

  .discard-info {
    padding: var(--spacing-md);
    display: flex;
    align-items: center;
    grid-gap: 5px;
    flex-direction: column;

    ${media.greaterThan('medium')`
      padding: 40px var(--spacing-xl) var(--spacing-md);
    `}

    p {
      font-size: 16px;
      color: var(--text-opacity);
    }
  }

  .vertical-button-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div {
      cursor: pointer;
      padding: var(--spacing-sm);
      border-top: 1px solid var(--border);
      width: 100%;

      &:hover {
        background: #f9f9f9;
      }

      &.negative {
        color: var(--negative);
      }
    }
  }
`
