import { useEffect } from 'react'

type Event = `${string}.verified`

export default function useWebsocket(
  event: Event,
  cb: (data: any, error?: string) => void
) {
  useEffect(() => {
    let connection: WebSocket

    try {
      if (!window['WebSocket']) {
        console.error('Websockets are not supported in your browser')
        return
      }

      connection = new WebSocket(`wss://pusher.cevoid.com/ws?events=${event}`)

      connection.onclose = function (evt) {
        console.error('Websocket event:', evt)
      }

      connection.onmessage = async (e) => {
        try {
          const data = parseJson(e.data)

          if (!data) {
            return
          }

          cb(data)
        } catch (error) {
          cb(undefined, error)
        }
      }

      connection.onerror = (error) => {
        cb(undefined, 'Failed to open websocket connection')
      }
    } catch (error) {
      console.error(error)
      cb(undefined, error.message)
    }

    return () => {
      if (connection) {
        connection.close()
      }
    }
  }, [event])
}

const parseJson = (data) => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return undefined
  }
}
