import ButtonGroup, {
  Style as ButtonGroupStyle
} from 'components/ButtonGroup/ButtonGroup'

import Button from 'components/Button/Button'
import Incentive from 'components/Incentive/Incentive'
import PoweredBy from 'components/PoweredBy/PoweredBy'
import UploadedPosts from 'components/UploadedPosts/UploadedPosts'
import { fadeAnimation } from 'ui/animations/animations'
import media from 'styled-media-query'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'

export default function ThanksForSharing({ ...props }) {
  const mode = useStore((state) => state.mode)
  const clear = useStore((state) => state.clear)
  const closeApp = useStore((state) => state.closeApp)
  const origin = useStore((state) => state.origin)
  const posts = useStore((state) => state.posts)
  const domain = useStore((state) => state.company?.domain)
  const { getString } = useTranslations()

  const showBackToWebsiteButton = !!origin || !!domain
  return (
    <Style {...props}>
      <div className="header"></div>
      <div className="body">
        <UploadedPosts posts={posts} />
        <motion.div variants={fadeAnimation} initial="hidden" animate="show">
          <h1>{getString('thanksForSharing', 'Thanks for sharing!')}</h1>
          <p>
            {getString(
              'thanksForSharingDescription',
              'We greatly appreciate your contribution, keep a look out on our website for you post.'
            )}
          </p>
        </motion.div>
        <Incentive />
      </div>
      <motion.div
        className="footer"
        variants={fadeAnimation}
        initial="hidden"
        animate="show"
      >
        <ButtonGroup direction="column">
          <Button
            color="primary"
            expanded
            onClick={() => {
              mode === 'page' && showBackToWebsiteButton ? clear() : closeApp()
            }}
          >
            {mode === 'page'
              ? getString('uploadMore', 'Upload more content')
              : getString('done', 'Done')}
          </Button>
          {mode === 'page' && showBackToWebsiteButton && (
            <Button
              color="light"
              expanded
              onClick={() => {
                closeApp()
              }}
            >
              {getString('goToWebsite', 'Go to website')}
            </Button>
          )}
        </ButtonGroup>
        <PoweredBy className="mobile" />
      </motion.div>
    </Style>
  )
}

const Style = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  grid-gap: var(--spacing-lg);
  height: 100vh;
  min-height: -webkit-fill-available;
  height: 100dvh;

  ${media.greaterThan('medium')`
    max-width: 500px;
    padding: var(--spacing-xl) var(--spacing-md) var(--spacing-md);
    min-height: auto;
    height: auto;
  `}

  .header {
    display: block;

    ${media.greaterThan('medium')`
      display: none;
    `}
  }

  .body {
    grid-gap: var(--spacing-md);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
  }

  h1 {
    font-size: var(--font-lg);
  }

  p {
    margin-top: var(--spacing-xs);
    color: var(--text-opacity);
  }

  .footer {
    grid-gap: var(--spacing-sm);
    display: flex;
    flex-direction: column;

    ${ButtonGroupStyle} {
      ${media.lessThan('medium')`
        flex-direction: column;
      `}
    }

    .upload-more {
      svg {
        transition: 0.5s all ease-out;
      }
      &:hover {
        svg {
          transform: rotate(-360deg);
        }
      }
    }
  }
`
