import React, { useEffect, useRef, useState } from 'react'
import { Trash, Zoom } from 'ui/icons'
import styled, { css } from 'styled-components'

import { Crop } from 'icons'
import Cropper from 'react-easy-crop'
import { isDesktop } from 'react-device-detect'
import media from 'styled-media-query'
import { motion } from 'framer-motion'
import { preloadImage } from 'utils/generalUtils'
import useElementSize from 'utils/useElementSize'
import { useStore } from 'datalayer/store'

const PORTRAIT_ASPECT = 9 / 16
const LANDSCAPE_ASPECT = 16 / 9

export default function Media({ src, type, current }) {
  const ref = useRef(null)
  const set = useStore((state) => state.set)
  const posts = useStore((state) => state.posts)
  const removePost = useStore((state) => state.removePost)
  const [minZoom, setMinZoom] = useState(1)
  const [zoomSlider, setZoomSlider] = useState(false)
  const [loading, setLoading] = useState(true)
  const [orientation, setOrientation] = useState(undefined)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [aspect, setAspect] = useState(1)
  const [imageAspect, setImageAspect] = useState(undefined)
  const [zoom, setZoom] = useState(aspect)
  const [naturalHeight, setNaturalHeight] = useState(undefined)
  const [naturalWidth, setNaturalWidth] = useState(undefined)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [observe, { width, height }] = useElementSize()

  const isVideo = type?.includes('video')
  const isImage = type?.includes('image')

  const getLandscapeAspect = () => {
    if (imageAspect > LANDSCAPE_ASPECT) {
      return LANDSCAPE_ASPECT
    }
    return imageAspect
  }

  const getPortraitAspect = () => {
    if (imageAspect < PORTRAIT_ASPECT) {
      return PORTRAIT_ASPECT
    }
    return imageAspect
  }

  const getZoom = () => {
    if (orientation === 'portrait') {
      setMinZoom(naturalHeight / naturalWidth)
      return naturalHeight / naturalWidth
    }
    if (orientation === 'landscape') {
      setMinZoom(imageAspect)
      return imageAspect
    }

    return 1
  }

  const getZoom2 = () => {
    setMinZoom(1)
    if (orientation === 'portrait' && imageAspect < PORTRAIT_ASPECT) {
      return PORTRAIT_ASPECT / imageAspect
    }
    if (orientation === 'landscape' && imageAspect > LANDSCAPE_ASPECT) {
      return imageAspect / LANDSCAPE_ASPECT
    }

    return 1
  }

  const getCropSize = () => {
    if (orientation === 'portrait') {
      return {
        width: width * (aspect === 1 ? aspect : getPortraitAspect()),
        height: height
      }
    }
    if (orientation === 'landscape') {
      return {
        width: width,
        height: height / (aspect === 1 ? aspect : getLandscapeAspect())
      }
    }
    return {
      width: width,
      height: height
    }
  }

  const toggleAspectRatio = () => {
    if (aspect === 1) {
      setAspect(
        orientation === 'landscape' ? getLandscapeAspect() : getPortraitAspect()
      )
      setZoom(getZoom2())
      setTimeout(() => {
        setCrop({ x: 0, y: 0 })
      }, 1)

      return
    }
    setAspect(1)
    setZoom(getZoom())
    setTimeout(() => {
      setCrop({ x: 0, y: 0 })
    }, 1)
  }

  const initImage = () => {
    setAspect(
      orientation === 'landscape' ? getLandscapeAspect() : getPortraitAspect()
    )
    setZoom(getZoom2())
    setTimeout(() => {
      setCrop({ x: 0, y: 0 })
    }, 1)
  }

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)

    set((state) => {
      state.posts = state.posts.map((item) => {
        if (item.preview === src) {
          return {
            ...item,
            cropData: croppedAreaPixels
          }
        }

        return item
      })
    })
  }

  const toggleZoomSlider = () => {
    setZoomSlider(!zoomSlider)
  }

  const load = async (src) => {
    await preloadImage(src)

    if (loading) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isVideo) {
      setLoading(false)
    }

    if (isImage) {
      load(src)

      let img = new Image()

      img.addEventListener('load', function () {
        setNaturalHeight(this.naturalHeight)
        setNaturalWidth(this.naturalWidth)
        setImageAspect(this.naturalWidth / this.naturalHeight)
        if (this.naturalHeight === this.naturalWidth) {
          setOrientation('square')
        } else if (this.naturalHeight > this.naturalWidth) {
          setOrientation('portrait')
        } else if (this.naturalHeight < this.naturalWidth) {
          setOrientation('landscape')
        }
      })

      img.src = src
    }

    return () => {
      setLoading(false)
    }
  }, [src])

  return (
    <>
      {loading ? null : (
        <Style
          key={'media'}
          className={isImage ? 'image-media' : 'video-media'}
          ref={observe}
          current={current}
        >
          <div className="media-container">
            <Cropper
              {...(isVideo ? { video: src } : { image: src })}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              objectFit={
                orientation === 'square' ? 'horizontal-cover' : 'contain'
              }
              zoomWithScroll={false}
              showGrid={zoom !== 1}
              cropSize={getCropSize()}
              onMediaLoaded={() => {
                initImage()
              }}
              onCropComplete={onCropComplete}
            />

            <div className="image-action-buttons left">
              {orientation !== 'square' && isImage && (
                <div
                  className="crop-button action-button"
                  onClick={() => toggleAspectRatio()}
                >
                  <Crop />
                </div>
              )}
              {isDesktop && isImage && (
                <div className="relative">
                  <div
                    className={`zoom-button action-button ${
                      zoomSlider ? 'active' : ''
                    }`}
                    onClick={() => toggleZoomSlider()}
                  >
                    <Zoom />
                  </div>

                  {zoomSlider && (
                    <ZoomSlider percentage={zoom / 2}>
                      <input
                        type="range"
                        min={minZoom}
                        max={2}
                        onChange={(e) => setZoom(e.target.valueAsNumber)}
                        step="0.01"
                        value={zoom}
                        className="slider"
                      />
                    </ZoomSlider>
                  )}
                </div>
              )}
            </div>
            <div className="image-action-buttons right">
              {posts?.length > 0 && (
                <div
                  className="trash-button action-button"
                  onClick={() => removePost(src)}
                >
                  <Trash />
                </div>
              )}
            </div>
          </div>
        </Style>
      )}
    </>
  )
}

const Style = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;

  &.video-media {
    .media-container {
      pointer-events: none;
    }
  }

  ${(props) =>
    props.current &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

  .media-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  video {
    width: 100%;
    height: 100%;
  }

  .reactEasyCrop_Container {
    border-radius: var(--border-radius);
    transform: translateZ(0);
  }

  .reactEasyCrop_Contain {
    ${media.greaterThan('medium')`
      transition: 0.08s transform linear;
    `}
  }

  .reactEasyCrop_CropArea {
    border: none !important;
    box-shadow: none !important;
  }
`

const ZoomSlider = styled(motion.div)`
  background: rgb(54 54 54 / 70%);
  padding: 15px 10px;
  border-radius: 6px;
  top: -41px;
  width: 120px;
  left: 0px;
  position: absolute;
  display: flex;

  .slider {
    appearance: none;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: rgb(255 255 255 / 40%);
    outline: none;

    &:after {
      width: ${(props) => props.percentage}%;
      height: 2px;
      border-radius: 5px;
      background: #fff;
      outline: none;
    }
  }

  .slider::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }
`
