import { Close } from 'ui/icons'
import CompanyLogo from 'components/CompanyLogo/CompanyLogo'
import Dropzone from 'components/Dropzone/Dropzone'
import PoweredBy from 'components/PoweredBy/PoweredBy'
import React from 'react'
import { cevoidApiUrl } from 'config'
import { getQueryString } from 'utils/generalUtils'
import { isDesktop } from 'react-device-detect'
import media from 'styled-media-query'
import { motion } from 'framer-motion'
import { optionsAnimation } from 'ui/animations/animations'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'

export default function UploadOptions({ ...props }) {
  const origin = useStore((state) => state.origin)
  const closeApp = useStore((state) => state.closeApp)
  const uploadTypes = useStore((state) => state.uploadTypes)
  const { getString } = useTranslations()

  const closeUpload = () => {
    closeApp()
  }

  return (
    <Style {...props}>
      <div className="upload-options-wrapper">
        <div className="header"></div>
        <motion.div
          className="options-wrapper"
          variants={optionsAnimation}
          initial="hidden"
          animate="show"
        >
          <CompanyLogo />
          <div className="welcome-header">
            <h1>{getString('welcomeTitle', 'Share your content with us!')}</h1>
            {getString('welcomeDescription', '') && (
              <p className="welcome-description">
                {getString('welcomeDescription', '')}
              </p>
            )}
          </div>
          <div className="upload-options">
            {uploadTypes?.directUploads && (
              <Dropzone>
                <motion.div
                  key="computer-upload"
                  className="upload-option computer-upload"
                >
                  <div className="upload-icon computer mac">
                    <img src="/images/computer-mac-icon.png" alt="Upload" />
                  </div>
                  <div className="upload-icon ios">
                    <img src="/images/ios.png" alt="Upload" />
                  </div>
                  <div className="upload-title">
                    {getString('upload', 'Upload')}
                  </div>
                </motion.div>
              </Dropzone>
            )}
          </div>
        </motion.div>
        <div className="footer">
          <PoweredBy className="mobile" />
        </div>
      </div>

      {origin && (
        <div
          className="close"
          onClick={() => {
            closeUpload()
          }}
        >
          <Close />
        </div>
      )}
    </Style>
  )
}

const Style = styled.div`
  height: 100vh;
  min-height: -webkit-fill-available;
  height: 100dvh;

  ${media.greaterThan('medium')`
    min-height: auto;
    height: auto;
    min-width: 500px;
  `}

  .welcome-header {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    max-width: 500px;
  }

  .welcome-description {
    color: var(--text-opacity);
    max-width: 600px;
  }

  .upload-options-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: var(--spacing-md);
    padding: 20px;
    height: 100%;

    ${media.greaterThan('medium')`
      padding: var(--spacing-lg) var(--spacing-xl);
      height: auto;
    `}
  }

  .options-wrapper {
    grid-gap: var(--spacing-md);
    flex-direction: column;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-options {
    grid-gap: 20px;
    display: flex;

    ${media.greaterThan('medium')`
      grid-gap: var(--spacing-md);
    `}

    .upload-option {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: var(--border-radius);
      border: 1px solid var(--border);
      cursor: pointer;
      transition: 0.15s all ease-in-out;
      box-shadow: 0px 0px 15px -2px rgb(0 0 0 / 0%);
      padding: 20px 35px;
      color: #000;

      ${media.greaterThan('medium')`
        padding: var(--spacing-md) var(--spacing-lg);
      `}

      .upload-icon.computer {
        display: none;
        ${media.greaterThan('medium')`
          display: flex;
        `}
      }

      .upload-icon.ios {
        display: flex;
        ${media.greaterThan('medium')`
          display: none;
        `}
      }

      .upload-icon {
        width: 70px;
        height: 70px;
        border-radius: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 35px;
          height: 35px;
          object-fit: cover;
          transition: 0.15s ease-in-out;
        }

        &.android {
          border: 1px solid #ddd;
        }

        &.ios {
          border: 1px solid #ddd;
        }

        &.computer {
          background-color: #d4edf9;
        }

        &.tiktok {
          background-color: #000;
        }

        &.instagram {
          background: linear-gradient(
            45deg,
            rgb(240, 148, 51) 0%,
            rgb(250, 118, 72) 25%,
            rgb(255, 44, 77) 50%,
            rgb(242, 46, 124) 75%,
            rgb(243, 34, 177) 100%
          );
        }
      }

      .upload-title {
        margin-top: var(--spacing-sm);

        ${media.greaterThan('medium')`
          margin-top: var(--spacing-md);
        `}
      }

      &:hover {
        box-shadow: 0px 4px 20px -2px rgb(0 0 0 / 15%);

        .upload-icon {
          img {
            transform: scale(1.15);
          }
        }
      }
    }
  }

  .close {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 100px;
    position: absolute;
    top: 10px;
    right: var(--spacing-sm);

    ${media.greaterThan('medium')`
      display: none;
    `}

    &:hover {
      background: var(--light-active);
    }
  }
`
