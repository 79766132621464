import { AnimatePresence, motion } from 'framer-motion'
import { ArrowLeft, Close, QuestionMark } from 'ui/icons'
import { errorTextAnimation, fadeAnimation } from 'ui/animations/animations'
import { resendMail, verifyPendingUpload } from 'datalayer/api/upload'

import Button from 'components/Button/Button'
import CompanyLogo from 'components/CompanyLogo/CompanyLogo'
import Loader from 'components/Loader/Loader'
import VerificationInput from 'react-verification-input'
import media from 'styled-media-query'
import styled from 'styled-components'
import { useState } from 'react'
import { useStore } from 'datalayer/store'
import useWebsocket from 'hooks/useWebsocket'
import useTranslations from 'hooks/useTranslations'

export default function VerificationCode({ ...props }) {
  const authType = useStore((state) => state.authType)
  const email = useStore((state) => state.email)
  const setView = useStore((state) => state.setView)
  const origin = useStore((state) => state.origin)
  const setDiscard = useStore((state) => state.setDiscard)
  const setShouldClose = useStore((state) => state.setShouldClose)
  const [loading, setLoading] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [codeError, setCodeError] = useState(undefined)
  const [disableResend, setDisableResend] = useState(false)
  const pendingUploadId = useStore((state) => state.pendingUploadId)
  const { getString } = useTranslations()

  const goBack = () => {
    // if (authType) {
    //   setView(authType)
    //   return
    // }
    setView('new-member')
  }

  const resendVerificationCode = async () => {
    if (disableResend) return

    setLoading(true)
    setDisableResend(true)
    try {
      await resendMail(pendingUploadId)

      setDisableResend(false)
      setLoading(false)
    } catch (error) {
      if (
        error.message.includes(
          'Pending upload failed due to many failed verifications'
        )
      ) {
        setCodeError(
          getString(
            'tooManyTries',
            'Too many verification tries, please start over'
          )
        )
      }
      if (error.message.includes('Pending upload already verified')) {
        setCodeError(
          getString('alreadyVerified', 'Upload has already been verified')
        )
      }
      setDisableResend(true)
      setLoading(false)
    }
  }

  const verifyCode = async (code) => {
    setLoading(true)

    try {
      await verifyPendingUpload({
        id: pendingUploadId,
        code
      })
      setTimeout(() => {
        setView('thanks-for-sharing')
      }, 1000)
    } catch (error) {
      if (error.message.includes('Invalid code')) {
        setCodeError(getString('invalidCode', 'Invalid verification code'))
        setLoading(false)
        return
      }
      if (error.message.includes('Pending upload already verified')) {
        setCodeError(
          getString('alreadyVerified', 'Upload has already been verified')
        )
      }
      if (error.message.includes('Token or code has expired')) {
        setCodeError(getString('codeExpired', 'Code has expired'))
      }
      if (
        error.message.includes(
          'Pending upload failed due to many failed verifications'
        )
      ) {
        setCodeError(
          getString(
            'tooManyTries',
            'Too many verification tries, please start over'
          )
        )
      }

      setDisableResend(true)
      setLoading(false)
    }
  }

  useWebsocket(`${pendingUploadId}.verified`, (data, error) => {
    if (error) {
      return
    }

    setTimeout(() => {
      setView('thanks-for-sharing')
    }, 1000)
  })

  return (
    <AnimatePresence exitBeforeEnter>
      <Style {...props}>
        <motion.div
          key={'verification-code'}
          className={'verification-code'}
          variants={fadeAnimation}
          initial={'hidden'}
          animate={'show'}
          exit={'hidden'}
        >
          <div className="header">
            <div className="back-arrow" onClick={() => goBack()}>
              <ArrowLeft />
            </div>
            <div className="right">
              {origin && (
                <div
                  className="close"
                  onClick={() => {
                    setDiscard(true)
                    setShouldClose(true)
                  }}
                >
                  <Close />
                </div>
              )}
            </div>
          </div>
          <div className="body">
            <CompanyLogo />
            <div className="title">
              <h1>
                {`${getString(
                  'verificationCodeTitle',
                  'Enter the 5-digit code we emailed to'
                )} ${email}`}
              </h1>
              <p>
                {getString(
                  'verificationCodeDescription',
                  'This helps us keep your account secure by verifying that it is really you'
                )}
              </p>
            </div>
            <div className="verification-input">
              <AnimatePresence exitBeforeEnter>
                {!loading ? (
                  <motion.div
                    key="verification-input"
                    variants={verificationAnimation}
                    initial="show"
                    exit="hidden"
                    animate="show"
                  >
                    <VerificationInput
                      value={verificationCode}
                      autoFocus={true}
                      validChars="0-9"
                      placeholder={''}
                      length={5}
                      inputProps={{ inputMode: 'numeric' }}
                      onChange={(value) => {
                        if (value?.length < 5) {
                          setCodeError(undefined)
                        }
                        setVerificationCode(value)
                      }}
                      onComplete={(code) => {
                        verifyCode(code)
                      }}
                    />
                  </motion.div>
                ) : (
                  <motion.div
                    className="verification-loader"
                    key="verification-loader"
                    variants={verificationAnimation}
                    initial="hidden"
                    exit="hidden"
                    animate="show"
                  >
                    <Loader />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <AnimatePresence>
              <div className="error-container">
                {codeError && (
                  <motion.div
                    key="code-error"
                    variants={errorTextAnimation}
                    initial="hidden"
                    exit="hidden"
                    animate="show"
                    className="error"
                  >
                    {codeError}
                  </motion.div>
                )}
              </div>
            </AnimatePresence>
          </div>
          <div className="footer">
            <motion.div className="submit">
              {!disableResend && (
                <Button
                  color="light"
                  size="small"
                  onClick={() => resendVerificationCode()}
                  disabled={loading}
                >
                  {getString('resendCode', 'Resend code')}
                </Button>
              )}
              <Button
                color="plain"
                size="small"
                disabled={loading}
                onClick={() => goBack()}
              >
                {getString('cancel', 'Cancel')}
              </Button>
            </motion.div>
          </div>
        </motion.div>
      </Style>
    </AnimatePresence>
  )
}

const Style = styled.div`
  .verification-code {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    min-height: -webkit-fill-available;
    height: 100dvh;

    ${media.greaterThan('medium')`
      justify-content: unset;
      height: auto;
      max-width: 500px;
      min-height: auto;
      min-width: 480px;
    `}
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px var(--spacing-sm);

    ${media.greaterThan('medium')`
      padding: var(--spacing-sm);
    `}

    .back-arrow {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 100px;

      &:hover {
        background: var(--light-active);
      }
    }

    .right {
      display: flex;
      align-items: center;
      grid-gap: var(--spacing-xs);
    }

    .info-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 100px;

      a {
        display: flex;
      }

      &:hover {
        background: var(--light-active);
      }
    }

    .close {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 100px;

      ${media.greaterThan('medium')`
          display: none;
        `}

      &:hover {
        background: var(--light-active);
      }
    }
  }

  .body {
    flex-direction: column;
    grid-gap: var(--spacing-sm);
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    padding: 20px;

    ${media.greaterThan('medium')`
      padding: 0px var(--spacing-md) var(--spacing-md);
    `}

    .title {
      display: flex;
      flex-direction: column;
      grid-gap: 5px;

      p {
        color: var(--text-opacity);
        max-width: 300px;
        margin: 0px auto;
      }
    }

    .verification-input {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      position: relative;
      margin: 10px 0px;

      .vi__container {
        height: 60px;
      }

      .vi__character {
        border-radius: 4px;
        font-size: 26px;
        height: 60px;
        line-height: 60px;
      }

      .vi__character--inactive {
        border: 1px solid #ccc;
        background: #fff;
      }

      .vi__character--selected {
        border: 1px solid #000;
        outline: 3px solid rgba(0, 0, 0, 0.2);
        color: #000;
      }
    }

    .verification-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .error-container {
      height: 20px;
      .error {
        color: var(--negative);
      }
    }
  }

  .footer {
    padding: 20px;

    ${media.greaterThan('medium')`
      padding: 0px var(--spacing-md) var(--spacing-md);
    `}

    .submit {
      text-align: center;
      flex-direction: column;
      grid-gap: var(--spacing-sm);
      display: inline-flex;
      align-items: center;
      width: 100%;
    }
  }
`

export const verificationAnimation = {
  hidden: {
    opacity: 0,
    transition: { ease: 'easeInOut', duration: 0.15 }
  },
  show: {
    opacity: 1,
    transition: { ease: 'easeInOut', duration: 0.15 }
  }
}
