import { Policy, useStore } from 'datalayer/store'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Props as ButtonProps } from '../Button/Button'
import Checkbox, { Style as CheckboxStyle } from 'components/Input/Checkbox'

export default function Consent({
  items: policies,
  children,
  onChange
}: {
  items: Policy[] | undefined
  children?: React.ReactNode
  onChange?: (data: { acceptedPolicies: string[]; isValid: boolean }) => void
}) {
  const { acceptedPolicies, set } = useStore()

  const { checkboxes, marketingCheckbox, marketingSimpleText, simpleText } = (
    policies.filter((notNull) => notNull) || []
  ).reduce(
    (acc, policy) => {
      if (policy.displayFormat === 'CHECKBOXES') {
        acc.checkboxes.push(policy)
      } else if (policy.displayFormat === 'SIMPLE_TEXT') {
        acc.simpleText.push(policy)
      }

      if (
        policy.marketingDisplayFormat === 'CHECKBOXES' &&
        !!policy.marketingMessage
      ) {
        acc.marketingCheckbox.push(policy)
      } else if (policy.marketingDisplayFormat === 'SIMPLE_TEXT') {
        acc.marketingSimpleText.push(policy)
      }

      return acc
    },
    {
      checkboxes: [],
      simpleText: [],
      marketingCheckbox: [],
      marketingSimpleText: []
    } as {
      checkboxes: Policy[]
      simpleText: Policy[]
      marketingCheckbox: Policy[]
      marketingSimpleText: Policy[]
    }
  )

  // All checkbox policies must be accepted and have same id
  const isValid =
    acceptedPolicies.filter((id) => checkboxes.find((p) => p._id === id))
      .length === checkboxes.length

  useEffect(() => {
    if (typeof onChange !== 'function') {
      return
    }
    onChange({
      isValid,
      acceptedPolicies
    })
  }, [acceptedPolicies, isValid])

  if (!policies || policies.length === 0) {
    return <div className="cevoid-button-group">{children}</div>
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child
    }

    return React.cloneElement(child, {
      disabled: child.props.disabled || !isValid
    } as ButtonProps)
  })

  const togglePolicy = (id: string, accepted: boolean) => {
    if (accepted) {
      set((state) => {
        state.acceptedPolicies = [...state.acceptedPolicies, id]
      })
    } else {
      set((state) => {
        state.acceptedPolicies = state.acceptedPolicies.filter((p) => p !== id)
      })
    }
  }

  return (
    <Style>
      <div className="checkboxes">
        {checkboxes.map((policy) => {
          return (
            <SinglePolicy
              key={policy._id}
              policy={policy}
              onChange={(accepted) => {
                togglePolicy(policy._id, accepted)
              }}
            />
          )
        })}
        {marketingCheckbox.map((policy) => {
          return (
            <CheckboxPolicy
              text={policy.marketingMessage}
              onChange={(accepted) => {
                togglePolicy(`${policy._id}.marketing`, accepted)
              }}
            />
          )
        })}
      </div>
      <div className="cevoid-button-group">{childrenWithProps}</div>
      <div className="disclaimer">
        {simpleText.map((policy) => {
          return <SinglePolicy key={policy._id} policy={policy} />
        })}
        {marketingSimpleText.map((policy) => {
          return <div key={policy._id}>{policy.marketingMessage}</div>
        })}
      </div>
    </Style>
  )
}

const CheckboxPolicy = ({
  text,
  onChange
}: {
  text: string
  onChange?: (accepted: boolean) => void
}) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(checked)
    }
  }, [checked])

  return (
    <div className="terms-check">
      <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
      <div>{text}</div>
    </div>
  )
}

const SinglePolicy = ({
  policy,
  onChange
}: {
  policy: Policy
  onChange?: (accepted: boolean) => void
}) => {
  const [accepedTos, setAcceptedTos] = useState(false)

  useEffect(() => {
    if (typeof onChange !== 'function') {
      return
    }

    onChange(accepedTos)
  }, [accepedTos])

  const linksInMessage = policy.message.match(/%{[a-zA-Z0-9]+}%/g) || []
  const messageParts = getMesageWithLinks(
    linksInMessage,
    policy.message,
    policy.includedLinks
  )

  return (
    <>
      {policy.displayFormat === 'CHECKBOXES' && (
        <div className="terms-check">
          <Checkbox
            checked={accepedTos}
            onChange={() => setAcceptedTos(!accepedTos)}
          />
          <div>{messageParts}</div>
        </div>
      )}

      {policy.displayFormat === 'SIMPLE_TEXT' && <div>{messageParts}</div>}
    </>
  )
}

const getMesageWithLinks = (
  links: string[],
  message: string,
  includedLinks: Policy['includedLinks'],
  parts: any[] = []
) => {
  if (parts.length === 0 && links.length === 0) {
    return [message]
  }

  const link = links.shift()

  if (!link) {
    return parts
  }

  const [firstPart, secondPart] = message.split(link)
  parts.push(firstPart)

  // Insert link
  const foundLink = includedLinks.find((l) => `%{${l.key}}%` === link)
  parts.push(
    <a
      href={`https://${foundLink?.url}`}
      target="_blank"
      style={{ display: message.includes(link) ? 'inline' : 'none' }}
    >
      {foundLink?.ctaText}
    </a>
  )

  if (!secondPart) {
    return parts
  }

  if (!secondPart.includes('%{')) {
    parts.push(secondPart)
    return parts
  }

  return getMesageWithLinks(links, secondPart, includedLinks, parts)
}

const Style = styled.div`
  .checkboxes {
    margin-bottom: var(--spacing-sm);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    font-size: var(--font-xs);
    color: var(--text-opacity);

    .terms-check {
      display: flex;
      align-items: flex-start;
      gap: var(--spacing-xs);

      ${CheckboxStyle} {
        margin-top: 2px;
      }
    }
  }

  .checkboxes:empty {
    margin-bottom: 0;
  }

  a,
  .clickable {
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
  }

  .disclaimer {
    font-size: var(--font-xs);
    color: var(--text-opacity);
    text-align: center;
    margin-top: var(--spacing-sm);
  }

  .cevoid-button-group {
    display: flex;
    flex-direction: column;
  }
`
