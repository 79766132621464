import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useStore } from 'datalayer/store'
import { validateVideoLength } from 'utils/generalUtils'

const MAX_VIDEO_LENGTH = 10 * 60 // 10 minutes in seconds
const ONE_MB = 1048576 // One mb in bytes
const MAX_FILE_SIZE = 200 * ONE_MB

export default function Dropzone({ children, ...props }) {
  const setPosts = useStore((state) => state.setPosts)
  const setView = useStore((state) => state.setView)
  const setError = useStore((state) => state.setError)
  const setContentSource = useStore((state) => state.setContentSource)

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'video/mp4': ['.mp4', '.MP4'],
      'video/mov': ['.mov', '.MOV'],
      'image/png': ['.png', '.PNG'],
      'image/jpeg': ['.jpeg', '.jpg', '.JPG', '.JPEG'],
      'image/webp': ['.webp', '.WEBP']
    },
    maxFiles: 5,
    maxSize: MAX_FILE_SIZE,
    onDropRejected: (fileRejections) => {
      fileRejections?.map((rejected) =>
        rejected?.errors?.map((error) => {
          if (error?.code === 'file-too-large') {
            setError('max-filesize')
          }
          if (error?.code === 'too-many-files') {
            setError('max-upload')
          }
          if (error?.code === 'file-invalid-type') {
            setError('file-invalid-type')
          }
        })
      )
    },
    onDropAccepted: async (acceptedFiles) => {
      setContentSource('upload')

      for (const file of acceptedFiles) {
        if (file.type.includes('video')) {
          const isValid = await validateVideoLength(file, MAX_VIDEO_LENGTH)

          if (!isValid) {
            return setError(
              'custom',
              `Max video length is ${MAX_VIDEO_LENGTH} seconds`
            )
          }
        }
      }

      setPosts(
        acceptedFiles.map((file) => ({
          file,
          preview: URL.createObjectURL(file)
        }))
      )
      setView('edit-posts')
    }
  })

  return (
    <Style
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      {...props}
    >
      <input {...getInputProps()} />
      {children}
    </Style>
  )
}

export const Style = styled(motion.div)``
