import { ArrowLeft, ChevronLeft, ChevronRight, Close } from 'ui/icons'
import { Fragment, useEffect } from 'react'
import styled, { css } from 'styled-components'

import Button from 'components/Button/Button'
import Caption from 'components/Caption/Caption'
import { MAX_CAPTION_LENGTH } from 'consts'
import Media from 'components/Media/Media'
import { fadeAnimation } from 'ui/animations/animations'
import { getCroppedImg } from 'utils/generalUtils'
import media from 'styled-media-query'
import { motion } from 'framer-motion'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'

export default function EditPosts({
  visible,
  ...props
}: {
  visible?: boolean
}) {
  const set = useStore((state) => state.set)
  const origin = useStore((state) => state.origin)
  const posts = useStore((state) => state.posts)
  const postIndex = useStore((state) => state.postIndex)
  const setPostIndex = useStore((state) => state.setPostIndex)
  const decrementPostIndex = useStore((state) => state.decrementPostIndex)
  const incrementPostIndex = useStore((state) => state.incrementPostIndex)
  const setView = useStore((state) => state.setView)
  const setDiscard = useStore((state) => state.setDiscard)
  const setShouldClose = useStore((state) => state.setShouldClose)
  const { getString } = useTranslations()

  const onCaptionChange = (preview, value) => {
    if (value.length > MAX_CAPTION_LENGTH) {
      return
    }
    set((state) => {
      state.posts = state.posts.map((item) => {
        if (item.preview === preview) {
          return {
            ...item,
            caption: value
          }
        }

        return item
      })
    })
  }

  const cropImages = async (image, crop) => {
    const file = await getCroppedImg(image, crop)
    return file
  }

  const submitPosts = async () => {
    const unresolvedPromises = posts.map((item) => {
      // Dont crop videos for now
      if (item.file.type.includes('video')) {
        return item.file
      }

      return cropImages(item?.preview, item?.cropData)
    })

    const results = await Promise.all(unresolvedPromises)

    set((state) => {
      state.posts = state.posts.map((item, index) => {
        return {
          ...item,
          crop: results[index]
        }
      })
    })

    setView('new-member')
  }

  const goBack = () => {
    setDiscard(true)
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (
        ['textarea', 'input'].includes(
          event?.srcElement?.tagName?.toLowerCase()
        )
      ) {
        return
      }

      if (event.keyCode === 37) {
        decrementPostIndex()
      }
      if (event.keyCode === 39) {
        incrementPostIndex()
      }
    }
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <Style {...props}>
      <div className="edit-post-wrapper">
        <div className="header">
          <div className="back-arrow" onClick={() => goBack()}>
            <ArrowLeft />
          </div>
          {posts.length > 1 && (
            <div className="post-count">
              {postIndex + 1}/{posts?.length}
            </div>
          )}
          <Button
            color="primary"
            size="small"
            onClick={() => submitPosts()}
            className="large-icon continue-button"
          >
            <span>{getString('continue', 'Continue')}</span>
            <ChevronRight />
          </Button>
          {origin && (
            <div
              className="close"
              onClick={() => {
                setDiscard(true)
                setShouldClose(true)
              }}
            >
              <Close />
            </div>
          )}
        </div>

        <div className="body">
          <div className="media-wrapper-outer">
            <motion.div
              className="media-wrapper"
              variants={fadeAnimation}
              initial="hidden"
              animate="show"
            >
              {posts?.map((post, index) => (
                <Fragment key={index}>
                  <Media
                    key={`media-${index}`}
                    current={index === postIndex}
                    src={post?.preview}
                    type={post?.file?.type}
                  />
                </Fragment>
              ))}
            </motion.div>

            <NavigationButton
              className="navigation-button previous"
              disabled={postIndex === 0}
              onClick={() => setPostIndex(postIndex - 1)}
            >
              <ChevronLeft />
            </NavigationButton>
            <NavigationButton
              className="navigation-button next"
              onClick={() => setPostIndex(postIndex + 1)}
              disabled={postIndex + 1 === posts?.length}
            >
              <ChevronRight />
            </NavigationButton>
          </div>

          <div className="info-wrapper">
            <Caption
              key={postIndex}
              preview={posts?.[postIndex]?.preview}
              caption={posts?.[postIndex]?.caption}
              onCaptionChange={onCaptionChange}
            />
            <div className="caption-description">
              {getString(
                'captionDescription',
                'Add an inspirational caption or more details for the community'
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer"></div>
    </Style>
  )
}

const Style = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  min-height: -webkit-fill-available;
  height: 100dvh;

  ${media.greaterThan('medium')`
    min-height: auto;
    height: auto;
  `}

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px var(--spacing-sm);

    ${media.greaterThan('medium')`
      padding: var(--spacing-sm);
    `}

    .back-arrow {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 100px;

      &:hover {
        background: var(--light-active);
      }
    }

    .close {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 100px;

      ${media.greaterThan('medium')`
        display: none;
      `}

      &:hover {
        background: var(--light-active);
      }
    }

    .post-count {
      background: var(--light-active);
      border-radius: 100px;
      padding: 0px 10px 0px 12px;
      letter-spacing: 2px;
      display: flex;
      align-items: center;
      height: 30px;

      ${media.lessThan('medium')`
        position: absolute;
        right: 30px;
        top: 64px;
        z-index: 1;
        background: rgb(54 54 54 / 70%);
        color: #fff;
        font-size: 14px;
        height: 24px;
        padding: 0px 7px 0px 9px;
      `}
    }
  }

  .body {
    display: grid;
    grid-gap: 20px;

    ${media.greaterThan('medium')`
      grid-template-columns: 1fr 250px;
      grid-gap: var(--spacing-md);
    `}

    .media-wrapper-outer {
      padding: 0px 0px 0px 20px;
      position: relative;

      ${media.greaterThan('medium')`
        padding: 0px 0px var(--spacing-sm) var(--spacing-sm);
      `}
    }

    .media-wrapper {
      position: relative;
      border-radius: var(--border-radius);
      height: calc(100vw - 40px);
      width: calc(100vw - 40px);

      ${media.greaterThan('medium')`
        height: 70vh;
        width: 70vh;
        max-height: 800px;
        max-width: 800px;
      `}

      .image-action-buttons {
        position: absolute;
        display: flex;
        grid-gap: 8px;
        bottom: 10px;
        ${media.greaterThan('medium')`
          bottom: 15px;
        `}

        &.left {
          left: 10px;
          ${media.greaterThan('medium')`
            left: 15px;
          `}
        }

        &.right {
          right: 10px;
          ${media.greaterThan('medium')`
            right: 15px;
          `}
        }

        .relative {
          position: relative;
        }

        .action-button {
          cursor: pointer;
          background: rgb(54 54 54 / 70%);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          border: 1px solid rgb(255 255 255 / 8%);
          transition: 0.2s background ease-in-out;
          box-shadow: 0px 0px 10px 0px rgb(0 0 0, 0.2);
          width: 30px;
          height: 30px;

          ${media.greaterThan('medium')`
            width: 40px;
            height: 40px;
          `}

          &:hover {
            background: rgb(20 20 20 / 70%);
          }

          svg {
            color: #fff;
            height: 14px;
            width: 14px;
            min-width: 14px;

            ${media.greaterThan('medium')`
              height: 18px;
              width: 18px;
              min-width: 18px;
            `}

            &.zoom {
              fill: #fff;
            }
          }

          &.active {
            background: rgba(255, 255, 255, 0.9);

            svg {
              color: #000;
            }
          }

          &.trash-button {
            ${media.greaterThan('medium')`
              &:hover {
                background: var(--negative);
                opacity: 1;
              }
            `}
          }
        }
      }
    }

    .info-wrapper {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      padding: 0px 20px 20px;

      ${media.greaterThan('medium')`
        padding: 0px var(--spacing-sm) var(--spacing-sm) 0px;
      `}

      .caption-description {
        font-size: 12px;
        line-height: 1.2em;
        color: var(--text-opacity);
      }
    }
  }

  .footer {
    padding: 0px 20px 20px;
    display: block;

    ${media.greaterThan('medium')`
      display: none;
    `}
  }
`

const NavigationButton = styled.div`
  cursor: pointer;
  background: rgb(54 54 54 / 70%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: absolute;
  border: 1px solid rgb(255 255 255 / 8%);
  top: 50%;
  transform: translateY(-50%);
  transition: 0.15s background ease-in-out;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0, 0.2);
  width: 30px;
  height: 30px;

  ${media.greaterThan('medium')`
    width: 40px;
    height: 40px;
  `}

  &:hover {
    background: rgb(20 20 20 / 70%);
  }

  svg {
    color: #fff;
    height: 20px;
    width: 20px;
    min-width: 20px;

    ${media.greaterThan('medium')`
      height: 30px;
      width: 30px;
      min-width: 30px;
    `}
  }

  &.previous {
    left: 30px;

    ${media.greaterThan('medium')`
      left: 15px;
    `}

    svg {
      margin-right: 2px;
      ${media.greaterThan('medium')`
        margin-right: 3px;
      `}
    }
  }

  &.next {
    right: 30px;

    ${media.greaterThan('medium')`
      right: 15px;
    `}

    svg {
      margin-left: 2px;
      ${media.greaterThan('medium')`
        margin-left: 3px;
      `}
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`
