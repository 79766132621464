import { loadingChild, loadingParent } from 'ui/animations/animations'

import media from 'styled-media-query'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'

export default function Uploading() {
  const progress = useStore((state) => state.progress)
  const { getString } = useTranslations()
  return (
    <Style
      variants={loadingParent}
      animate={'show'}
      initial={'hidden'}
      exit={'exit'}
    >
      <div className="uploading-wrapper">
        <motion.div variants={loadingChild} className="uploading loading-dots">
          {getString('uploading', 'Uploading')}
        </motion.div>
        <motion.div variants={loadingChild} className="title">
          {progress
            ? `${progress?.count} ${getString('outOf', 'out of')} ${
                progress?.total
              }`
            : ''}
        </motion.div>
      </div>
    </Style>
  )
}

export const Style = styled(motion.div)`
  text-align: center;
  letter-spacing: -0.03em;
  padding: var(--spacing-lg);
  height: 100vh;
  min-height: -webkit-fill-available;
  height: 100dvh;

  ${media.greaterThan('medium')`
    width: 500px;
    max-height: 387px;
    min-height: auto;
    height: auto;
  `}

  .uploading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 20px;
    height: 100%;
  }

  .uploading {
    font-size: var(--font-md);
    line-height: 1em;
    letter-spacing: -0.03em;
    color: var(--text-opacity);
  }

  .title {
    font-size: 40px;
    letter-spacing: -0.03em;
    line-height: 1em;
  }

  .loading-dots:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;

    @keyframes dots {
      0%,
      20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
      }
      40% {
        color: #000;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
      }
      60% {
        text-shadow: 0.25em 0 0 #000, 0.5em 0 0 rgba(0, 0, 0, 0);
      }
      80%,
      100% {
        text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
      }
    }
  }
`
