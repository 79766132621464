import { AnimatePresence, motion } from 'framer-motion'
import { ContentSource, useStore } from 'datalayer/store'
import { postAnimation, postParentAnimation } from 'ui/animations/animations'

import { POST_TYPE } from 'consts'
import React from 'react'
import styled from 'styled-components'

type PostFields = {
  src: string
  isVideo: boolean
  key: string
}

function getPostFields(post, contenSource: ContentSource): PostFields {
  switch (contenSource) {
    case 'upload':
      return {
        src: URL.createObjectURL(post?.crop) || post?.preview,
        isVideo: post?.file?.type?.includes('video'),
        key: post?.preview || post?.id
      }
    case 'verify':
      return {
        src: post?.location,
        isVideo: post?.type === POST_TYPE.VIDEO,
        key: post?.location
      }
    default:
      throw new Error('Invalid content source')
  }
}

export default function UploadedPosts({ posts }) {
  const contentSource = useStore((state) => state.contentSource)

  return (
    <AnimatePresence>
      <Style
        className={`posts-${posts?.slice(0, 4).length}`}
        variants={postParentAnimation}
        initial="hidden"
        animate="show"
        exit="hidden"
        key="uploaded-posts"
      >
        <motion.div>
          {posts?.slice(0, 4)?.map((post, index) => {
            const fields = getPostFields(post, contentSource)

            return (
              <motion.div
                key={fields.key}
                className="post"
                variants={postAnimation}
                initial={`hidden${index}`}
                animate={`show${index}`}
                exit={`hidden${index}`}
              >
                <motion.div>
                  {fields.isVideo ? (
                    <video autoPlay={true} playsInline={true} muted loop>
                      <source src={fields.src} type="video/mp4" />
                    </video>
                  ) : (
                    <img src={fields.src} />
                  )}
                </motion.div>
              </motion.div>
            )
          })}
        </motion.div>
      </Style>
    </AnimatePresence>
  )
}

export const Style = styled(motion.div)`
  position: relative;
  height: 150px;
  width: 100%;

  .post {
    position: relative;

    & > div {
      overflow: hidden;
      aspect-ratio: 0.8;
      border-radius: 6px;
      box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.15);
      height: 150px;
      position: absolute;

      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        border-radius: 6px;
      }
    }
  }

  &.posts-5 {
    .post {
      &:nth-child(1) {
        z-index: 3;
        & > div {
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:nth-child(2) {
        z-index: 2;
        & > div {
          left: 50%;
          transform: translateX(0%) rotate(8deg) scale(0.9);
        }
      }
      &:nth-child(3) {
        z-index: 2;
        & > div {
          left: 50%;
          transform: translateX(-100%) rotate(-8deg) scale(0.9);
        }
      }
      &:nth-child(4) {
        z-index: 1;
        & > div {
          left: 50%;
          transform: translate(-140%, 10px) rotate(-8deg) scale(0.7);
        }
      }
      &:nth-child(5) {
        z-index: 1;
        & > div {
          left: 50%;
          transform: translate(40%, 10px) rotate(8deg) scale(0.7);
        }
      }
    }
  }
  &.posts-4 {
    .post {
      &:nth-child(1) {
        z-index: 3;
        & > div {
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:nth-child(2) {
        z-index: 2;
        & > div {
          left: 50%;
          transform: translateX(0%) rotate(8deg) scale(0.9);
        }
      }
      &:nth-child(3) {
        z-index: 2;
        & > div {
          left: 50%;
          transform: translateX(-100%) rotate(-8deg) scale(0.9);
        }
      }
      &:nth-child(4) {
        z-index: 1;
        & > div {
          left: 50%;
          transform: translate(-140%, 10px) rotate(-8deg) scale(0.7);
        }
      }
    }
  }
  &.posts-3 {
    .post {
      &:nth-child(1) {
        z-index: 3;
        & > div {
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:nth-child(2) {
        z-index: 2;
        & > div {
          left: 50%;
          transform: translateX(0%) rotate(8deg) scale(0.9);
        }
      }
      &:nth-child(3) {
        z-index: 2;
        & > div {
          left: 50%;
          transform: translateX(-100%) rotate(-8deg) scale(0.9);
        }
      }
    }
  }
  &.posts-2 {
    .post {
      &:nth-child(1) {
        z-index: 2;
        & > div {
          left: 50%;
          transform: translateX(-85%) rotate(-5deg);
        }
      }
      &:nth-child(2) {
        & > div {
          left: 50%;
          transform: translateX(-15%) rotate(8deg) scale(0.9);
        }
      }
    }
  }
  &.posts-1 {
    .post {
      & > div {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`
