import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'

import { copyToClipboard } from 'utils/generalUtils'
import { fadeAnimation } from 'ui/animations/animations'
import media from 'styled-media-query'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'

export default function Incentive({ ...props }) {
  const [copied, setCopied] = useState(false)
  const incentive = useStore((state) => state.incentive)
  const { getString } = useTranslations()

  const copyDiscount = (code) => {
    setCopied(true)
    copyToClipboard(code)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  if (!incentive) {
    return null
  }

  return (
    <Style {...props} variants={fadeAnimation} initial="hidden" animate="show">
      <div
        className="incentive-button"
        onClick={() => copyDiscount(incentive?.code)}
      >
        {incentive?.code}
        <AnimatePresence>
          {copied && (
            <motion.div
              variants={copyAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="copied"
            >
              {getString('copied', 'Copied!')}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="incentive-info">
        {incentive?.value} {getString('discount', 'discount')}
      </div>
    </Style>
  )
}

export const Style = styled(motion.div)`
  display: flex;
  align-items: center;
  grid-gap: 8px;
  position: relative;
  justify-content: center;
  flex-direction: column;

  .incentive-info {
    font-size: 14px;
  }

  .incentive-button {
    border: 2px solid #000;
    color: #000;
    border-radius: 25px;
    padding: 10px 20px;
    display: inline-block;
    font-family: 'Courier';
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    max-width: 240px;
    min-width: 160px;
    position: relative;
    user-select: text;
    word-break: break-word;

    ${media.greaterThan('medium')`
      border: 2px solid var(--text);

      &:hover {
        background: #fff;
        color: #000;
        opacity: 0.6;
      }
    `}
  }

  .copied {
    position: absolute;
    bottom: 0px;
    font-size: 14px;
    left: 0px;
    z-index: 1;
    text-transform: initial;
    letter-spacing: 0px;
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
  }
`

export const copyAnimation = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: { ease: 'easeInOut', duration: 0.15 }
  },
  show: { opacity: 1, y: 0, transition: { ease: 'easeInOut', duration: 0.15 } }
}
