import styled, { css } from 'styled-components'

import React from 'react'

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  scheme?: string
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, scheme, ...props }, ref) => {
    return (
      <Style className={className} $hidden={props.hidden} $scheme={scheme}>
        <input ref={ref} type={'checkbox'} {...props} />
      </Style>
    )
  }
)

export default Checkbox

export const Style = styled.div<{
  $hidden?: boolean
  $scheme?: string
}>`
  position: relative;

  ${(props) =>
    props.$hidden &&
    css`
      position: absolute;
      height: 0px;
      top: 0px;
      left: 0px;
    `}

  input {
    appearance: auto;
    &:focus {
      border: 1px solid var(--${(props) => props.$scheme}-accent) !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
`
