import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect } from 'react'

import Body from 'components/Body/Body'
import Discard from 'components/Discard/Discard'
import EditPosts from 'components/Steps/EditPosts/EditPosts'
import Error from 'components/Error/Error'
import FailedUpload from 'components/Steps/FailedUpload/FailedUpload'
import Head from 'next/head'
import NewMember from 'components/Steps/NewMember/NewMember'
import { Style as PoweredByStyle } from 'components/PoweredBy/PoweredBy'
import ThanksForSharing from 'components/Steps/ThanksForSharing/ThanksForSharing'
import UploadOptions from 'components/Steps/UploadOptions/UploadOptions'
import Uploading from 'components/Steps/Uploading/Uploading'
import VerificationCode from 'components/Steps/VerificationCode/VerificationCode'
import media from 'styled-media-query'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'

export default function UploadPage() {
  const image = '/images/thumbnail.png'

  const setMode = useStore((state) => state.setMode)
  const setOrigin = useStore((state) => state.setOrigin)
  const setSource = useStore((state) => state.setSource)
  const actions = useStore((state) => state.actions.upload)
  const company = useStore((state) => state.company)
  const view = useStore((state) => state.view)
  const discard = useStore((state) => state.discard)
  const error = useStore((state) => state.error)
  const { getString } = useTranslations()

  const title = getString('welcomeTitle', 'Share your content with us!')
  const router = useRouter()

  const { slug, accessToken, mode, origin, source } = router.query

  useEffect(() => {
    if (slug) {
      actions.init(slug as string, accessToken as string)
    }
  }, [slug])

  useEffect(() => {
    if (['modal', 'page'].includes(mode as string)) {
      setMode(mode as string)
    }

    if (origin) {
      setOrigin(origin as string)
    }

    if (source) {
      setSource(source as string)
    }
  }, [mode, origin, source])

  if (!company) {
    return null
  }

  return (
    <Style className={view}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, minimum-scale=1, maximum-scale=1"
        />
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:image" content={image} />
        <meta property="og:image:secure_url" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:card" content="summary" />
        <link
          rel="shortcut icon"
          type="image/png"
          href={company?.image?.location}
        />
        <link
          rel="apple-touch-icon"
          type="image/png"
          href={company?.image?.location}
        />
      </Head>
      <Body key="body">
        <AnimatePresence exitBeforeEnter>
          {view === 'upload-options' && (
            <UploadOptions key={'upload-options'} />
          )}
          {view === 'edit-posts' && <EditPosts key={'edit-posts'} />}
          {view === 'new-member' && <NewMember key={'new-member'} />}
          {view === 'verification-code' && (
            <VerificationCode key={'verification-code'} />
          )}
          {view === 'uploading' && <Uploading key={'uploading'} />}
          {view === 'failed-upload' && <FailedUpload key={'failed-upload'} />}
          {view === 'thanks-for-sharing' && (
            <ThanksForSharing key={'thanks-for-sharing'} />
          )}
        </AnimatePresence>
      </Body>
      <AnimatePresence exitBeforeEnter>
        {error && <Error key={'error'} />}
        {discard && <Discard key={'discard'} />}
      </AnimatePresence>
    </Style>
  )
}

export const Style = styled(motion.div)`
  position: relative;
  height: -webkit-fill-available;
  height: 100dvh;

  &.edit-posts,
  &.confirm-upload,
  &.instagram,
  &.failed-upload {
    ${PoweredByStyle} {
      ${media.lessThan('medium')`
        display: none;
      `}
    }
  }
`
