import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import EmojiPicker from 'components/EmojiPicker/EmojiPicker'
import { Smiley } from 'ui/icons'
import { isDesktop } from 'react-device-detect'
import media from 'styled-media-query'
import useClickOutside from 'utils/useClickOutside'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'

type Props = {
  preview: string
  caption: string
  onCaptionChange: (preview: string, caption: string) => void
}

export default function Caption({ preview, caption, onCaptionChange }: Props) {
  const isInstagram = false // contentSource === 'instagram'  would always be false since instagram is not a valid value for contentSource anymore
  const ref = useRef(null)
  const [emojiPicker, setEmojiPicker] = useState(false)
  const set = useStore((state) => state.set)
  const { getString } = useTranslations()

  const handleEmojiSelect = (emoji) => {
    addEmoji(preview, emoji)
    setEmojiPicker(false)
  }

  const addEmoji = (preview, emoji) => {
    set((state) => {
      state.posts = state.posts.map((item) => {
        if (item.preview === preview) {
          return {
            ...item,
            caption: item?.caption ? item?.caption?.concat(emoji) : emoji
          }
        }

        return item
      })
    })
  }

  const closeEmojiPicker = () => {
    setEmojiPicker(false)
  }

  useClickOutside(ref, closeEmojiPicker)

  return (
    <Style disabled={isInstagram}>
      <textarea
        id="caption"
        name="caption"
        placeholder={
          isInstagram ? '' : getString('writeACaption', 'Write a caption...')
        }
        value={caption}
        onChange={
          isInstagram
            ? undefined
            : (e) => onCaptionChange(preview, e.target.value)
        }
      />
      {isDesktop && !isInstagram && (
        <div className="emoji-wrapper" ref={ref}>
          <div className="relative">
            <div
              className="emoji-button"
              onClick={() => setEmojiPicker(!emojiPicker)}
            >
              <Smiley />
            </div>
            {emojiPicker && (
              <div className="emoji-popover">
                <EmojiPicker onChange={handleEmojiSelect} />
              </div>
            )}
          </div>
        </div>
      )}
    </Style>
  )
}

const Style = styled.div`
  position: relative;
  padding: 15px 15px 20px 15px;
  border-radius: 8px;
  border: 1px solid var(--border);

  ${media.lessThan('medium')`
    padding-bottom: 15px;
  `}

  textarea {
    width: 100%;
    height: 51px;
    border: none;
    resize: none;
    padding: 0px;
    background: none;
    font-size: var(--font-text);

    ${media.greaterThan('medium')`
      height: 100px;
      font-size: 16px;
    `}

    &:focus {
      outline: none;
    }
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;

          textarea {
            pointer-events: none;
            opacity: 0.5;
          }
        `
      : null}

  .emoji-wrapper {
    position: absolute;
    bottom: 5px;
    right: 5px;

    .relative {
      position: relative;

      .emoji-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 100px;

        svg {
          height: 18px;
          width: 18px;
        }

        &:hover {
          background: var(--light-active);
        }
      }

      .emoji-popover {
        z-index: 99;
        max-width: 300px;
        position: absolute;
        right: 0px;
        top: 35px;
        width: 290px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 2px 15px -2px rgb(0 0 0 / 15%);
        border: 1px solid #dfdfdf;
        padding: 10px;

        .emojis {
          max-height: 135px;
          overflow-y: auto;
        }

        .dropdown-menu {
          display: flex;
          flex: initial;
          flex-direction: column;
          background: var(--dropdown);
          box-shadow: var(--pop-box-shadow);
          border-radius: var(--border-radius);
          overflow: hidden;
          width: 100%;
          border: 1 px solid var(--border-light);
        }

        .title {
          margin-top: var(--spacing-sm);
          margin-bottom: var(--spacing-sm);
          font-size: var(--font-xs);

          &:first-of-type {
            margin-top: 0;
          }
        }

        .items {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(32px, 30px));
          grid-gap: 5px 0;

          .emoji {
            padding: var(--spacing-xs);
            cursor: pointer;

            transition: ease-in-out 140ms all;

            &:hover {
              background-color: var(--element-hover);
              border-radius: var(--border-radius);
            }
          }
        }
      }
    }
  }
`
