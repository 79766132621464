import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import Button from 'components/Button/Button'
import { motion } from 'framer-motion'

type Props = {
  id: string
  defaultValue?: string
  placeholder?: string
  type?: string
  error?: boolean
  errorMessage?: string
  value?: any
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  inputRef?: React.RefObject<HTMLInputElement>
  disabled?: boolean
  prepend?: React.ReactNode
  autoFocus?: boolean
  autoComplete?: string
  copy?: boolean
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      defaultValue,
      placeholder,
      type,
      error,
      errorMessage,
      onChange,
      inputRef,
      disabled,
      value,
      prepend,
      autoFocus,
      copy,
      autoComplete = 'off',
      ...props
    }: Props,
    ref
  ) => {
    const [focused, setFocused] = useState(false)

    return (
      <Style
        $hasPrepend={prepend}
        focused={focused}
        disabled={disabled}
        {...props}
      >
        <div
          className="input-element"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        >
          {prepend && <div className="prepend">{prepend}</div>}
          <input
            id={id}
            name={id}
            defaultValue={defaultValue}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            ref={ref}
            autoComplete={autoComplete}
            spellCheck="false"
            disabled={disabled}
            autoFocus={autoFocus}
          />
        </div>

        {copy ? (
          <Button className="copy" size="small">
            Copy
          </Button>
        ) : null}
      </Style>
    )
  }
)

export default Input

export const Style = styled(motion.div)`
  width: 100%;
  position: relative;

  .input-element {
    background: #efefef;
    color: var(--text);
    border-radius: 4px;
    width: 100%;
    outline: none;
    transition: 0.15s border var(--transition);
    font-size: var(--font-xs);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: default;

    input {
      padding: 10px 15px;
      border: 0px;
      outline: none;
      background: none;
      color: var(--text);
      width: 100%;
    }
  }
`
