import React, { useCallback, useState } from 'react'

import Input from 'components/Input/Input'
import { Search } from 'ui/icons'
import emojis from './emojis.json'
import styled from 'styled-components'

type Props = {
  onChange?: (emoji: string) => void
}

export default function EmojiPicker({ onChange }: Props) {
  const [query, setQuery] = useState('')

  const onEmojiSearch = (e) => {
    setQuery(e.target.value)
  }

  const emojisToRender = useCallback(() => {
    return Object.keys(emojis).map((category) => {
      const items = emojis[category]
        .map((emoji) => {
          if (emoji.keywords.join(',').includes(query.toLowerCase())) {
            return emoji
          }

          return null
        })
        .filter((notNull) => notNull)

      if (items.length <= 0) {
        return null
      }

      return (
        <>
          <div key={category} className="title">
            {category}
          </div>
          <div key={`${category}-items`} className="items">
            {items.map((item, i) => (
              <div
                key={i}
                className="emoji"
                onClick={() => {
                  onChange(item.emoji)
                }}
              >
                {item.emoji}
              </div>
            ))}
          </div>
        </>
      )
    })
  }, [query])

  return (
    <Style>
      <div className="emoji-search">
        <Search />
        <Input
          onChange={onEmojiSearch}
          value={query}
          id="emoji-search"
          placeholder="Search"
          autoFocus
        />
      </div>
      <div className="emojis">{emojisToRender()}</div>
    </Style>
  )
}

const Style = styled.div`
  .emoji-search {
    position: relative;
    margin-bottom: var(--spacing-sm);

    input {
      width: 100%;
      position: relative;
      padding: 6px;
      padding-left: 29px;
    }

    svg {
      position: absolute;
      left: 8px;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
    }
  }
`
