import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'

import Button from 'components/Button/Button'
import { Close } from 'ui/icons'
import UploadedPosts from 'components/UploadedPosts/UploadedPosts'
import Uploading from 'components/Steps/Uploading/Uploading'
import { fadeAnimation } from 'ui/animations/animations'
import media from 'styled-media-query'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'

export default function FailedUpload({ ...props }) {
  const [loading, setLoading] = useState(false)
  const actions = useStore((state) => state.actions.upload)
  const setDiscard = useStore((state) => state.setDiscard)
  const setShouldClose = useStore((state) => state.setShouldClose)
  const failed = useStore((state) => state.failed)
  const origin = useStore((state) => state.origin)
  const retry = useStore((state) => state.retry)
  const setRetry = useStore((state) => state.setRetry)
  const closeApp = useStore((state) => state.closeApp)
  const { getString } = useTranslations()

  const tryAgain = async () => {
    try {
      setLoading(true)
      setRetry(retry + 1)
      const result = (await actions.uploadPosts(failed, false)) as any
      if (result?.error) {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Style {...props}>
      <AnimatePresence exitBeforeEnter>
        {loading ? (
          <Uploading key={'confirm-loading'} />
        ) : (
          <motion.div
            key={'confirm-upload-form'}
            className={'confirm-upload-form'}
            variants={fadeAnimation}
            initial={'hidden'}
            animate={'show'}
            exit={'hidden'}
          >
            <div className="header">
              <div></div>
              <div className="right">
                {origin && (
                  <div
                    className="close"
                    onClick={() => {
                      setDiscard(true)
                      setShouldClose(true)
                    }}
                  >
                    <Close />
                  </div>
                )}
              </div>
            </div>
            {retry < 1 ? (
              <>
                <div className="body">
                  <UploadedPosts posts={failed} />
                  <div>
                    <h1>{getString('failedTitle', 'Upload failed')}</h1>
                    <div>
                      {getString(
                        'failedDescription',
                        'The posts above failed to upload'
                      )}
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <motion.div className="submit">
                    <Button color="primary" onClick={() => tryAgain()} expanded>
                      {getString('tryAgain', 'Try again')}
                    </Button>
                  </motion.div>
                </div>
              </>
            ) : (
              <>
                <div className="body">
                  <div>
                    <h1>{getString('megaFailTitle', 'Unable to upload')}</h1>
                    <div>
                      {getString(
                        'megaFailDescription',
                        'There seems to be something wrong right now. Our team has been notified and will solve the issue as soon as possible!'
                      )}
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <Button
                    color="primary"
                    expanded
                    onClick={() => {
                      closeApp()
                    }}
                  >
                    {getString('goToWebsite', 'Go to website')}
                  </Button>
                </div>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Style>
  )
}

const Style = styled.div`
  .confirm-upload-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    min-height: -webkit-fill-available;
    height: 100dvh;

    ${media.greaterThan('medium')`
      justify-content: unset;
      height: auto;
      max-width: 500px;
      min-height: auto;
    `}
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px var(--spacing-sm);

    ${media.greaterThan('medium')`
      padding: var(--spacing-sm);
    `}

    .right {
      display: flex;
      align-items: center;
      grid-gap: var(--spacing-xs);
    }

    .close {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 100px;

      ${media.greaterThan('medium')`
          display: none;
        `}

      &:hover {
        background: var(--light-active);
      }
    }
  }

  .body {
    flex-direction: column;
    grid-gap: var(--spacing-md);
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    padding: 20px;

    ${media.greaterThan('medium')`
      padding: var(--spacing-lg) var(--spacing-xl) var(--spacing-md);
    `}

    h1 {
      margin-bottom: var(--spacing-xs);
    }
  }

  .footer {
    padding: 20px;

    ${media.greaterThan('medium')`
      padding: 0px var(--spacing-xl) var(--spacing-md);
    `}

    .submit {
      text-align: center;
    }
  }
`
